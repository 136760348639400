import React from 'react'
import {useDfpSlot} from "./UseDfpSlot";

function MidPageUnitThree() {
    useDfpSlot({
        path: '/17984023/tsd_mpu3',
        size: [[320, 100], [300, 250], [320, 50]],
        id: 'div-gpt-ad-1664347936715-0',
       });
  return (
    <div>
        <div id='div-gpt-ad-1664347936715-0' style={{'min-width': '300px','min-height': '50px'}}></div>
    </div>
  )
}

export default MidPageUnitThree