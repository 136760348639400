import React from 'react'
import { Outlet, Link } from "react-router-dom";

function Footer() {
    const alertUser = () =>{
        alert("Your email recorded, We will send you updates through email.")
    }
  return (
       <>
            <footer class="flex justify-center px-4 bg-gray-800 dark:text-white dark:bg-gray-900 footer__section">
                <div class="container py-6">
                    <h1 class="text-lg font-bold text-center lg:text-2xl color__white">
                        Join us and never miss <br/> out on our news and more.
                    </h1>

                    <div class="flex justify-center mt-6">
                        <div class="bg-white border rounded-md focus-within:ring dark:bg-gray-900 dark:border-gray-700 focus-within:border-blue-400 focus-within:ring-blue-300 focus-within:ring-opacity-40 dark:focus-within:border-blue-300">
                            <div class="flex flex-wrap justify-between md:flex-row">
                                <input type="email" class="p-2 m-1 text-sm text-gray-700 bg-transparent appearance-none focus:outline-none focus:placeholder-transparent" placeholder="Enter your email" aria-label="Enter your email"/>
                                <button onClick={()=>alertUser()} class="w-full px-3 py-2 m-1 text-sm font-medium tracking-wider text-white uppercase transition-colors duration-300 transform bg-gray-800 rounded-md dark:hover:bg-gray-600 dark:bg-gray-800 lg:w-auto hover:bg-gray-700">subscribe</button>
                            </div>
                        </div>
                    </div>

                    <hr class="h-px mt-6 bg-gray-200 border-none dark:bg-gray-700"/>

                    <div class="flex flex-col items-center justify-between mt-6 md:flex-row">
                        <div>
                            <a href="#" class="text-xl font-bold text-gray-800 transition-colors duration-300 dark:text-white hover:text-gray-700 dark:hover:text-gray-300">Brand</a>
                        </div>

                        <div class="flex mt-4 md:m-0">
                            <div class="mx-4">
                            <Link class="link my-2 text-white-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" to="/">HOME</Link>
                            <Link class="link my-2 text-white-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" to="/privacy-policy">PRIVACY POLICY</Link>
                            <Link class="link my-2 text-white-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" to="/about-us">ABOUT US</Link>
                            <Link class="link my-2 text-white-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" to="/contact-us">CONTACT US</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
       </>
    )
}

export default Footer