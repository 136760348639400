import React,{useEffect,useState} from 'react'
import moment from 'moment'
import ArticleOverviewContent from './ArticleOverviewContent'
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import baseURI from '../config'
import Spinner from './Spinner';
import { Base64 } from "js-base64";

function ArticleOverview(props) {
    // let {data} = props
    let [articleNum,setArticleNum] = useState(localStorage.getItem('articleNum'))    
    let [data,setData] = useState(null)

    let FetchSlugDataFromAPI = (slug) =>{
        axios.get(`${baseURI}/api/${slug}/`, {
          headers: {
            'Content-Type': 'application/json'
            }
        },)
        .then(function (response) {
          console.log(response,"wow2222");
          setData(response?.data)
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // always executed
        });  
         
      }
      
    useEffect(()=>{
        FetchSlugDataFromAPI(props?.match?.params?.slug)
        console.log(props.history.location.search,"propssss")
        let searchString = props?.history?.location?.search
        if(searchString!="" && searchString.length>10){
            let decodeURI = decodeUriComponent(searchString?.slice(4,Infinity))
            let decodeBase64URI = Base64.decode(decodeURI)
            window.location.href = decodeBase64URI;
        }
    },[])
    const decodeUriComponent = require('decode-uri-component');

  return (
        <>
           {
            (data)?
            <ArticleOverviewContent data={data} articleNum={articleNum}/>: 
            <div className='pos-center'>
              <Spinner/>
            </div>
           }

        </>
    )
}

export default withRouter(ArticleOverview);