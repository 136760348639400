import React,{useEffect,useState} from 'react'
import moment from "moment"
import { Outlet, Link } from "react-router-dom";
import MidPageUnitOne from "./Advertisements/MidPageUnitOne"
import MidPageUnitTwo from "./Advertisements/MidPageUnitTwo"
import MidPageUnitThree from "./Advertisements/MidPageUnitThree"
import MidPageUnitFour from "./Advertisements/MidPageUnitFour"

function TrendingArticles(props) {
    const {data} = props
    const [mappedArray,setMappedArray] = useState([]);

    useEffect(()=>{
        setMappedArray(data)
    },[data])

    const handleRecord = (e) => {
        // if(e=="")
        let articleNum = e.target.getAttribute("data-id");
        localStorage.setItem('articleNum',articleNum);
    }
  return (
        <>
            <div className='container'>
                <h1 class="text-3xl font-semibold text-gray-800 capitalize lg:text-2xl dark:text-white mt-8">TRENDING ARTICLES</h1>
                <div className='midpage__unit__one'>
                    <MidPageUnitOne/>
                </div>
                <div className='midpage__unit__two'>
                    <MidPageUnitTwo/>
                </div>
                <div className='midpage__unit__three'>
                    <MidPageUnitThree/>
                </div>
                <div className='midpage__unit__four'>
                    <MidPageUnitFour/>
                </div>
            </div>
            <div class="dark:bg-gray-800 dark:text-gray-50">
                <div class="container grid grid-cols-12 mx-auto">
                    <div class="flex flex-col justify-center col-span-12 align-middle bg-no-repeat bg-cover dark:bg-gray-700 lg:col-span-6 lg:h-auto div__comp" style={{"backgroundImage": `url(${(mappedArray[25]?.image?.includes("drive"))?(`https://drive.google.com/uc?export=view&id=${mappedArray[25]?.image?.split("/")[5]}`):(mappedArray[25]?.image)})`}}>
                        <div class="flex flex-col items-center p-8 py-12 text-center article">
                            <span>{moment(mappedArray[25]?.date).format("MMM Do YY")}</span>
                            <h1 class="py-4 text-5xl font-bold">{mappedArray[25]?.heading?.substr(0,100)}</h1>
                            <p class="pb-6">{mappedArray[25]?.summary}</p>
                            <Link to={`/article-overview/${mappedArray[25]?.slug}`} data-id={25} >
                                <div data-id={25} onClick={(e)=>handleRecord(e)}>
                                    <svg data-id={25} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-7 h-7 anchor">
                                        <path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div class="flex flex-col col-span-12 p-6 divide-y lg:col-span-6 lg:p-10 divide-gray-700 side__article">
                        {
                            mappedArray?.map((articles,i)=>(
                                (i<4)? 
                                <div class="pt-6 pb-4 space-y-2 flex">
                                    <div>
                                        <span>{moment(articles?.date).format("MMM Do YY")}</span>
                                        <h1 class="text-3xl font-bold">{articles?.heading?.substr(0,80)}...</h1>
                                         <p>{articles?.summary.substr(0,80)}</p>
                                        <Link to={`/article-overview/${articles?.slug}`} rel="noopener noreferrer"  class="inline-flex items-center py-2 space-x-2 text-sm dark:text-violet-400">
                                            <div data-id={i} className="read__more" onClick={(i)=>handleRecord(i)} >
                                                <span data-id={i}>Read more</span>
                                            </div>
                                        </Link>    
                                    </div>    
                                    <figure className="trending__pic">
                                        <img src={(articles?.image?.includes("drive"))?(`https://drive.google.com/uc?export=view&id=${articles?.image?.split("/")[5]}`):(articles?.image)} alt=""/>
                                    </figure>
                                </div>:
                                ""
                            ))
                        }
                       
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrendingArticles