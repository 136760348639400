import React from 'react'
import ContactUsContent from './ContactUsContent'
import Footer from './Footer'

function ContactUs() {
  return (
      <>
        <ContactUsContent/>
        <Footer/>
      </>

  )
}

export default ContactUs