import React from 'react'
import AboutUsContent from './AboutUsContent'
import Footer from './Footer'

function AboutUs() {
  return (
    <>
      <AboutUsContent/>
      <Footer/>
    </>
  )
}

export default AboutUs