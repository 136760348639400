import React from 'react'
import moment from 'moment'
import MidPageUnitOne from "./Advertisements/MidPageUnitOne"
import MidPageUnitTwo from "./Advertisements/MidPageUnitTwo"
import MidPageUnitThree from "./Advertisements/MidPageUnitThree"
import MidPageUnitFour from "./Advertisements/MidPageUnitFour"
import LeaderBoardOne from './Advertisements/LeaderBoardOne'
import LeaderBoardTwo from './Advertisements/LeaderBoardTwo'
import LeaderBoardThree from './Advertisements/LeaderBoardThree'

function ArticleOverviewContent(props) {
    let {data,articleNum} = props;
  return (
    <>
        <div className='midpage__unit__one'>
            <MidPageUnitOne/>
        </div>
        <div className='midpage__unit__two'>
            <MidPageUnitTwo/>
        </div>
        <div className='midpage__unit__three__updated'>
            <MidPageUnitThree/>
        </div>
        <main class="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 article__overview">
            <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
                <article class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
                    <header class="mb-4 lg:mb-6 not-format">
                        <address class="flex items-center mb-6 not-italic">
                            <div class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                                <img class="mr-4 w-16 h-16 rounded-full" src={(data?.author_image=="")?"https://p.kindpng.com/picc/s/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png":data?.author_image} alt="Jese Leos"/>
                                <div>
                                    <a href="#" rel="author" class="text-xl font-bold text-gray-900 dark:text-white">{data?.author}</a>
                                    <p class="minimize__source text-base font-light text-gray-500 dark:text-gray-400">Author, {`${data?.source}`}</p>
                                    <p class="minimize__date text-base font-light text-gray-500 dark:text-gray-400"><time pubdate datetime="2022-02-08" title="February 8th, 2022">{moment(data?.date).format("MMM Do YY")}</time></p>
                                </div>
                            </div>
                        </address>
                        <h2 class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">{data?.title}</h2>
                    </header>
                    <p class="lead">{data?.summary}</p>
                    <figure className='figure gdrive__image'>
                        <img src={(data?.image?.includes("drive"))?(`https://drive.google.com/uc?export=view&id=${data?.image?.split("/")[5]}`):(data?.image)} alt=""/>
                        <figcaption>{(data?.photo_credit=="Unknown Source")?("Digital art by Anonymous"):(data?.photo_credit)}</figcaption>
                    </figure>
                    <div className="leader__board__one">
                        <LeaderBoardOne/>
                    </div>
                    <p className="summary">{data?.description}</p>
                    <div className="leader__board__two">
                        <LeaderBoardTwo/>
                    </div>
                </article>
            </div>
        </main>
    </>
  )
}

export default ArticleOverviewContent