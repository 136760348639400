import React from 'react'
import MidPageUnitOne from "./Advertisements/MidPageUnitOne"
import MidPageUnitTwo from "./Advertisements/MidPageUnitTwo"
import LeaderBoardOne from './Advertisements/LeaderBoardOne'

function AboutUsContent() {
  return (
    <>
        <div className='midpage__unit__one'>
            <MidPageUnitOne/>
        </div>
        <div className='midpage__unit__two'>
            <MidPageUnitTwo/>
        </div>
        <div className="container about_content">
            <h2>About US</h2>
            <p>We promise not to ask the same questions as everyone else, and we also promise to try and cover every sport out there. We are primarily a site for sports content that will age well and is not news-focused, we leave that to the big boys and girls.</p>
            <p>We concentrate on interesting, high-quality sports content that answers questions and stimulates debate and discussion. Thanks for stopping by.</p>
            <p className="para__margin">Our editors, writers and team members are a reflection of our ethos, and we’re damn proud of them. They’re a bunch of enthusiastic, informed and eager content creators who are experts in their fields - and who want to bestow their expertise (and passionate views and opinions) on you. They’ll take you into the deepest corners of everything they know, from fashion and beauty to fitness, health, lifestyle, and interior design. We’re not just a team of faceless creators behind the screen, we’re a community of empowered and informed humans. Come on in; we’ve been waiting for you.</p>
            <div className="leader__board__one">
              <LeaderBoardOne/>
            </div>
        </div>
    </>
  )
}

export default AboutUsContent