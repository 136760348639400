import React, { useEffect, useState} from 'react'
import { Outlet, Link } from "react-router-dom";

function Navbar() {
    const [selected,setSelected] = useState("Home")
    const handleCheck= (e) =>{
        if(e.target.alt=="website logo"){
            setSelected("Home");
        }else{
            let text = e.target.innerText;
            setSelected(text);
        }
    }
    const [open,setOpen] = useState(false)
    const handleOpen = () =>{
        setOpen(true)
    }
    const handleClose = () =>{
        setOpen(false)
    }
  return (
    <>
        <nav x-data="{ isOpen: false }" class="relative bg-white shadow dark:bg-gray-800 ">
            <div class="container px-6 py-4 mx-auto md:flex md:justify-between md:items-center">
                <div class="flex items-center justify-between">
                    <div>
                        <Link class="text-2xl font-bold text-gray-800 transition-colors duration-300 transform dark:text-white lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300" to="/" onClick={(e)=>handleCheck(e)}>
                            <img src={'/theSportsDirectLogo.svg'} alt="website logo" style={{width:"20rem"}}/>
                        </Link>
                    </div>
                    <div class="flex lg:hidden">
                        {
                        open==true? <svg x-show="isOpen" onClick={()=>handleClose()} xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>:""
                        } 
                        {
                        open==false?<svg x-show="!isOpen" onClick={()=>handleOpen()}xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4 8h16M4 16h16" />
                        </svg>:""
                        }
                    </div>
                </div>
                {open==false?
                     <div x-cloak id={open?"show":"hide"} class="absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-white dark:bg-gray-800 md:mt-0 md:p-0 md:top-0 md:relative md:bg-transparent md:w-auto md:opacity-100 md:translate-x-0 md:flex md:items-center" >
                     <div class="flex flex-col md:flex-row md:mx-6">
                         <Link class="my-2 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" to="/" id={selected=="HOME"?"active":"inActive"} onClick={(e)=>handleCheck(e)}>HOME</Link>
                         <Link class="my-2 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" to="/privacy-policy" id={selected=="PRIVACY POLICY"?"active":"inActive"} onClick={(e)=>handleCheck(e)}>PRIVACY POLICY</Link>
                         <Link class="my-2 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" to="/about-us" id={selected=="ABOUT US"?"active":"inActive"} onClick={(e)=>handleCheck(e)}>ABOUT US</Link>
                         <Link class="my-2 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" to="/contact-us" id={selected=="CONTACT US"?"active":"inActive"} onClick={(e)=>handleCheck(e)}>CONTACT US</Link>
                     </div>
                 </div>:
                  <div id={open?"show":"hide"} class="absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-white dark:bg-gray-800 md:mt-0 md:p-0 md:top-0 md:relative md:bg-transparent md:w-auto md:opacity-100 md:translate-x-0 md:flex md:items-center" >
                  <div class="flex flex-col md:flex-row md:mx-6">
                      <Link class="my-2 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" to="/" id={selected=="HOME"?"active":"inActive"} onClick={(e)=>handleCheck(e)}>HOME</Link>
                      <Link class="my-2 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" to="/privacy-policy" id={selected=="PRIVACY POLICY"?"active":"inActive"} onClick={(e)=>handleCheck(e)}>PRIVACY POLICY</Link>
                      <Link class="my-2 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" to="/about-us" id={selected=="ABOUT US"?"active":"inActive"} onClick={(e)=>handleCheck(e)}>ABOUT US</Link>
                      <Link class="my-2 text-gray-700 transition-colors duration-300 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0" to="/contact-us" id={selected=="CONTACT US"?"active":"inActive"} onClick={(e)=>handleCheck(e)}>CONTACT US</Link>
                  </div>
              </div>
                }
               
            </div>
        </nav>

    </>
  )
}

export default Navbar