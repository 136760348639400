import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar';
import Home from './Components/Home'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivacyPolicy from './Components/PrivacyPolicy';
import AboutUs from './Components/AboutUs';
import ContactUs from './Components/ContactUs';
import ArticleOverview from './Components/ArticleOverview';
import axios from 'axios'
import React,{useEffect,useState} from 'react'
import OtherArticleOverview from './Components/OtherArticleOverview';
import ArticleTrending from './Components/ArticleTrendingOverview';
import ArticleTrendingOverview from './Components/ArticleTrendingOverview';
import baseURI from "./config"

function App() {
  const [data,setData] = useState([]);
  const [data2,setData2] = useState([]);
  const [status,setDataExtract] = useState(false);
  const [status2,setDataExtract2] = useState(false);
  const [mappedArray,setMappedArray] = useState([]);
  const [mappedArray2,setMappedArray2] = useState([]);
  const [mappedArray3,setMappedArray3] = useState([]);

let array = ['kzoVa--Eis4LYhA','c8eQR4-ZhW4d_Y-'];
let randomItem=(items)=> items[Math.floor(Math.random()*items.length)];
let JWT = randomItem(array);

  let FetchDataFromAPI = () =>{
    axios.get(`${baseURI}/api/extra/top-articles`, {
      headers: {
        
        'Content-Type': 'application/json'
    }
    },)
    .then(function (response) {
      setData([...response?.data])
      setDataExtract(true)
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });  
     
  }
  
    useEffect(()=>{
        FetchDataFromAPI();
    },[])
    useEffect(()=>{
      let finalArray=[];
      let headingArray = [];
      data?.map((elem,i)=>{
          if (i >= 30 && i<=45) {
              if(!headingArray.includes(elem.excerpt)){
                  headingArray.push(elem.excerpt)
                  finalArray.push(elem);
              }
          }
      })
      setMappedArray(finalArray)
    },[data])

    useEffect(()=>{
      let finalArray=[];
      let headingArray = [];
      data?.map((elem,i)=>{
          if (i >= 5 && i<30) {
              if(!headingArray.includes(elem.excerpt)){
                  headingArray.push(elem.excerpt)
                  finalArray.push(elem);
              }
          }
      })
      setMappedArray2(finalArray)
    },[data])


    useEffect(()=>{
      let finalArray=[];
      let headingArray = [];
      data?.map((elem,i)=>{
          if (i >= 0 && i<=4) {
              if(!headingArray.includes(elem.excerpt)){
                  headingArray.push(elem.excerpt)
                  finalArray.push(elem);
              }
          }
      })
      setMappedArray3(finalArray)
    },[data])
  return (
    <>
      <Router>
        <Navbar className="nav__section"/>
        <Route path='/' exact>
          <Home data={data} data2={data2} status={status} status2={status2}/>
        </Route>
        <Route path='/privacy-policy' exact>
          <PrivacyPolicy/>
        </Route>
        <Route path='/about-us' exact>
          <AboutUs/>
        </Route>
        <Route path='/contact-us' exact>
          <ContactUs/>
        </Route>
        <Route path='/article-overview/:slug' exact>
          <ArticleOverview data={mappedArray2}/>
        </Route>
      </Router>
    </>
  );
}

export default App;
