import React,{useEffect,useState} from 'react'
import Footer from './Footer'
import LatestArticle from './LatestArticle'
import Navbar from './Navbar'
import RssFeed from './RssFeed'
import ThreeArticles from './ThreeArticles'
import TrendingArticles from './TrendingArticles'
import { NavLink, withRouter } from "react-router-dom";
import axios from 'axios'
import Spinner from './Spinner'
import LeaderBoardOne from './Advertisements/LeaderBoardOne'
import LeaderBoardTwo from './Advertisements/LeaderBoardTwo'
import LeaderBoardThree from './Advertisements/LeaderBoardThree'


function Home(props) {
  let {data,data2,status,status2} = props
  return (
    <>
      {
        (status)?
        <main>
          <TrendingArticles data={data}/>
          <div className="leader__board__one">
            <LeaderBoardOne/>
          </div>
          <LatestArticle data={data}/>
          <div className="leader__board__two">
            <LeaderBoardTwo/>
          </div>
          <ThreeArticles data={data}/>
          <div className="leader__board__three">
            <LeaderBoardThree/>
          </div>
          <Footer/>
        </main>:
        <div className='pos-center'>
          <Spinner/>
        </div>
      }
      
    </>
  )
}

export default withRouter(Home)