import React from 'react'
import Footer from './Footer'
import PrivacyPolicyContent from './PrivacyPolicyContent'

function PrivacyPolicy() {
  return (
    <>
      <div>
        <PrivacyPolicyContent/>
        <Footer/>    
      </div>
    </>
  )
}

export default PrivacyPolicy