import React,{useEffect,useState} from 'react'
import moment from "moment"
import { Link } from "react-router-dom";

function ThreeArticles(props) {
    const {data} = props

    const [mappedArray,setMappedArray] = useState([]);

    useEffect(()=>{
        let finalArray=[];
        let headingArray = [];
        data?.map((elem,i)=>{
            if (i >= 30 && i<=45) {
                if(!headingArray.includes(elem.summary)){
                    headingArray.push(elem.summary)
                    finalArray.push(elem);
                }
            }
        })
        setMappedArray(finalArray)
    },[data])


    const handleRecord = (e) => {
        let articleNum = e.target.getAttribute("data-id");
        localStorage.setItem('articleNum',articleNum);
    }
  return (

    <section class="bg-white dark:bg-gray-900 ">
        <div class="container px-6 py-10 mx-auto">
            <div className='flex three__articles__flex'>
                {
                    mappedArray?.map((article,i)=>(
                        <div className="article__card">
                            <img class="object-cover object-center w-full h-64 rounded-lg lg:h-80" src={(article?.image?.includes("drive"))?(`https://drive.google.com/uc?export=view&id=${article?.image?.split("/")[5]}`):(article?.image)} alt=""/>

                            <div class="mt-8">
                                <h1 class="mt-4 text-xl font-semibold text-gray-800 dark:text-white">
                                    {article?.title}
                                </h1>
                                <div class="flex flex-col justify-between items-stretch mt-4">
                                    <div>
                                        <p class="text-lg font-medium text-gray-700 dark:text-gray-300 hover:text-gray-500 mt-1">
                                            {article?.summary}
                                        </p>

                                        <p class="text-sm text-gray-500 dark:text-gray-400 mt-2">{moment(article?.date).format("MMM Do YY")}</p>
                                    </div>

                                    <Link to={`/article-overview/${article?.slug}`} class="inline-block text-blue-500 underline hover:text-blue-400 text-left">
                                        <div data-id={i} onClick={(i)=>handleRecord(i)}>Read more</div>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    ))
                }
            </div>
            
        </div>
    </section>
    
    )
}

export default ThreeArticles