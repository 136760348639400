import React,{useEffect} from 'react'
import {useDfpSlot} from "./UseDfpSlot";

function LeaderBoardThree() {
    useDfpSlot({
        path: '/17984023/tsd_lb3',
        size: [728, 90],
        id: 'div-gpt-ad-1664347740979-0',
       });
  return (
    <div>
        <div id='div-gpt-ad-1664347740979-0' style={{'min-width': '728px','min-height': '90px'}}></div>
    </div>
  )
}

export default LeaderBoardThree