import React from 'react'
import MidPageUnitOne from "./Advertisements/MidPageUnitOne"
import MidPageUnitTwo from "./Advertisements/MidPageUnitTwo"
import MidPageUnitThree from "./Advertisements/MidPageUnitThree"
import MidPageUnitFour from "./Advertisements/MidPageUnitFour"
import LeaderBoardOne from './Advertisements/LeaderBoardOne'
import LeaderBoardTwo from './Advertisements/LeaderBoardTwo'
import LeaderBoardThree from './Advertisements/LeaderBoardThree'
function PrivacyPolicyContent() {
  return (
    <>
       <div className='midpage__unit__one'>
            <MidPageUnitOne/>
        </div>
        <div className='midpage__unit__two'>
            <MidPageUnitTwo/>
        </div>
        <div className='midpage__unit__three'>
            <MidPageUnitThree/>
        </div>
        <div className='midpage__unit__four'>
            <MidPageUnitFour/>
        </div>
      <div className="container privacy__content">
          <h2>Privacy Policy</h2>
          <p>Welcome to our privacy notice.</p>
          <p>Our company respects your privacy and is committed to protecting your personal data. This privacy policy will inform you as to how we look after your personal data and tell you about your privacy rights and how the law protects you. Please use the Glossary to understand the meaning of some of the terms used in this privacy policy. Any questions regarding this Policy and our privacy practices should be sent by email to contact@thisdomain.</p>
          <h2>Address</h2>
          <p>Rye St</p>
          <p>Bishops Stortford </p>
          <p>Hertfordshire</p>
          <p>London</p>
          <p>CM23 2HG</p>
          <h2>THE DATA WE COLLECT ABOUT YOU</h2>
          <p>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).We may collect, use, store and transfer different kinds of personal data about you which we have grouped together follows:</p>
          <div className="leader__board__one">
            <LeaderBoardOne/>
          </div>
          <nav>
            <li>Identity Data includes first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender.</li>
            <li>Contact Data includes billing address, delivery address, email address and telephone numbers.</li>
            <li>Technical Data includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website.</li>
            <li>Profile Data includes your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses.</li>
            <li>Usage Data includes information about how you use our website, products and services.</li>
            <li>Marketing and Communications Data includes your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
          </nav>
          <p>We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data may be derived from your personal data but is not considered personal data in law as this data does not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy notice. Please also see our Cookie Policy for further details

            We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.</p>
          <h2>IF YOU FAIL TO PROVIDE PERSONAL DATA</h2>
          <p>Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.</p>
          <h2>HOW IS YOUR PERSONAL DATA COLLECTED?</h2>
          <p>We use different methods to collect data from and about you including through:</p>
          <nav>
            <li>Direct interactions. You may give us your Identity and Contact details such as your full name, email address and phone number by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:</li>
            <li>create an account on our website;</li>
            <li>subscribe to our service or publications;</li>
            <li>request marketing to be sent to you;</li>
            <li>apply for a job with us;</li>
            <li>enter a competition, promotion or survey; or</li>
            <li>give us some feedback.</li>
            <li>Automated technologies or interactions. As you interact with our website, we may automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, pixels/beacons and statistical IDs. Please see our Cookie Policy for further details.</li>
          </nav>
          <h2>HOW WE USE YOUR PERSONAL DATA</h2>
          <p>Company will observe the following principles when processing your personal information:</p>
          <div className="leader__board__two">
            <LeaderBoardTwo/>
          </div>
          <nav>
            <li>Processing your personal information fairly, lawfully and in a transparent manner;</li>
            <li>Collecting your personal information for specified, legitimate purposes and not processing further in ways incompatible with those purposes;</li>
            <li>Collecting your personal information which is relevant to and not excessive for the purposes for which it is collected and used. We may render information anonymous when feasible and appropriate, depending on the nature of the data and the risks associated with the intended uses;</li>
            <li>Maintaining your personal information accurate, and where necessary, kept up-to-date. We will take reasonable steps to rectify or delete data that is inaccurate or incomplete;</li>
            <li>Keeping your personal information only as long as it is necessary for the purposes for which it was collected and processed;</li>
            <li>Processing your personal information in accordance with the individual’s legal rights;</li>
            <li>Taking appropriate technical, physical and organizational measures to keep your data safe and prevent unauthorized access, unlawful processing, and unauthorized or accidental loss, destruction, or damage to personal information;</li>
            <li>Processing your personal data only if it is lawful in accordance with a legal basis including if you have unambiguously given your consent to such process.</li>
          </nav>
          <h2>MARKETING</h2>
          <p>We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. We have established the following personal data control mechanisms:</p>
          <nav>
            <li>Promotional offers from us:
            We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you. You will receive marketing communications from us if you have requested information from us or purchased services from us and, in each case, you have not opted out of receiving that marketing.</li>
            <li>Third-party marketing: We will get your express opt-in consent before we share your personal data with any company outside the Company group of companies for electronic marketing purposes (including text or email) or by phone.</li>
            <li>Advertising/Targeting cookies: These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose. When you visit another site that works with us you will see ads that are tailored to your interests and in accordance with your preferences. We will not use our cookies and we will not permit the third parties who use our cookies, for the purposes of selecting audiences based on any sensitive information or special categories of data such as religious beliefs of ethnicity.</li>
            <li>Opting out: You can ask us or third parties to stop sending you marketing messages at any time by clicking on the "Object to Processing" link under "Your Legal Rights" below. Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product/service purchase, warranty registration, product/service experience or other transactions.</li>
          </nav>
          <h2>COOKIES</h2>
          <nav>
            <li>On our website: You can limit the way that we tailor advertising to you on our websites where this is based on your information by objecting to the cookies that we or third parties wish to place on your browser or deleting the cookies that we or third parties have placed on your browser during your use of our websites Please see our Cookie Policy for more details of how to delete these cookies.</li>
            <li>On third party websites: You can limit the way that advertising is tailored to you on third party websites where this is based on your personal information by objecting to the cookies that we or third parties wish to place on your browser or deleting the cookies that we or third parties have placed on your browser during your use of third party websites. Please see the cookie policy published on the applicable third party website for more details of how to do this. After you have exercised your right to object or reject the use of cookies, you may still see advertisements, but the advertising may not be as relevant to your interests. If you change your computer, change your browser or delete certain cookies, you will need to renew your preferences.</li>
          <li>Personalised Advertising: If you want to opt-out of future Personalised Advertising on all of our websites and our Partner websites please see our Cookie Policy by clicking here. For more on information on opting-out please also see NAI Consumer Opt Out..</li>
          </nav>
          
      
          <h2>CHANGE OF PURPOSE</h2>
          <p>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us by sending an email. If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</p>
          <h2>DISCLOSURES OF PERSONAL DATA</h2>
          <p>We may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above.</p>
          <nav>
            <li>Internal Third Parties as set out in the Glossary.</li>
            <li>External Third Parties as set out in the Glossary.</li>
            <li>Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice.</li>
          </nav>
          <p>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>
          <h2>INTERNATIONAL TRANSFERS</h2>
          <p>We share your personal data within the Company Group. This will involve transferring your data outside the UK and/or the European Economic Area (EEA).

            Many of our external third parties are based outside the UK and/or the European Economic Area (EEA) so their processing of your personal data will involve a transfer of data outside the UK and/or the EEA.</p>
          <p>Whenever we transfer your personal data out of the UK and/or the EEA, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:</p>
          <nav>
            <li>We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission and/or the UK’s ICO. For further details, see European Commission: Adequacy of the protection of personal data in non-EU countries and https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/international-transfers-after-uk-exit/</li>
            <li>Where we use certain service providers, we may use specific contracts approved by the European Commission which give personal data the same protection it has in Europe. For further details, see European Commission: Model contracts for the transfer of personal data to third countries.</li>
          </nav>
          <p>Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of the UK and/or the EEA.</p>
          <div className="leader__board__three">
            <LeaderBoardThree/>
          </div>
          <h2>DATA SECURITY</h2>
          <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.

          We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where an applicable data protection law requires us to do so.</p>
          <p>Company websites or services may provide links to third-party applications, products, services, or websites for your convenience and information. If you access those links, you will leave the Company website. Company does not control those third-party websites or their privacy practices, which may differ from Company practices. We do not endorse or make any representations about third-party websites. The personal data you choose to provide to or that is collected by these third parties are not covered by the Company Data Protection Policy. We encourage you to review the privacy policy of any website you interact with before allowing the collection and use of your personal data.

          We may also provide social media links that enable you to share data with your social networks and to interact with Company on various social media sites. Your use of these links may result in the collection or sharing of data about you, depending on the feature. We encourage you to review the privacy policies and settings on the social media sites with which you interact to make sure you understand the data that may be collected, used, and shared by those sites.

          If you post, comment, indicate interest, or share personal data, including photographs, to any public forum, social network, blog, or other such forum, please be aware that any personal data you submit can be read, viewed, collected, or used by other users of these forums, and could be used to contact you, send you unsolicited messages, or for purposes that neither you nor Company have control over. Company is not responsible for the personal data you choose to submit in these forums.</p>
          <h2>LINKS TO THIRD PARTY WEBSITES</h2>
          <p>Company websites or services may provide links to third-party applications, products, services, or websites for your convenience and information. If you access those links, you will leave the Company website. Company does not control those third-party websites or their privacy practices, which may differ from Company practices. We do not endorse or make any representations about third-party websites. The personal data you choose to provide to or that is collected by these third parties are not covered by the Company Data Protection Policy. We encourage you to review the privacy policy of any website you interact with before allowing the collection and use of your personal data.

          We may also provide social media links that enable you to share data with your social networks and to interact with Company on various social media sites. Your use of these links may result in the collection or sharing of data about you, depending on the feature. We encourage you to review the privacy policies and settings on the social media sites with which you interact to make sure you understand the data that may be collected, used, and shared by those sites.</p>
          <p>If you post, comment, indicate interest, or share personal data, including photographs, to any public forum, social network, blog, or other such forum, please be aware that any personal data you submit can be read, viewed, collected, or used by other users of these forums, and could be used to contact you, send you unsolicited messages, or for purposes that neither you nor Company have control over. Company is not responsible for the personal data you choose to submit in these forums.</p>
          <h2>DATA RETENTION</h2>
          <p>How long will you use my personal data for?

          We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.

          To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
          <h2>YOUR LEGAL RIGHTS</h2>
          <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data.</p>
          <nav>
            <li>Request access to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it. Click here</li>
            <li>Request correction of the personal data that we hold about you. This enables you to have any inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</li>
            <li>Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request</li>
            <li>Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</li>
            <li>Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data's accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
            <li>Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</li>
            <li>Withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</li>
          </nav>
          <p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</p>
          <h2>What we may need from you</h2>
          <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
          <h2>Time limit to respond</h2>
          <p>We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
          <h2>CHANGES TO THIS POLICY</h2>
          <p>Company reserves the right to modify this privacy statement as needed, for example, to comply with changes in laws, regulations, Company practices and procedures, or requirements imposed by data protection authorities.Last update: July 2021</p>
          <h2>GLOSSARY</h2>
          <h2>LAWFUL BASIS</h2>
          <nav>
            <li>Legitimate Interest means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.</li>
            <li>Performance of Contract means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</li>
            <li>Comply with a legal or regulatory obligation means processing your personal data where it is necessary for compliance with a legal or regulatory obligation that we are subject to.</li>
          </nav>
          <h2>THIRD PARTIES</h2>
          <h2>INTERNAL THIRD PARTIES</h2>
          <p>Other companies in the Company Group acting as joint controllers or processors and who are based in the UK, Ireland, Canada and the UAE and provide administration or marketing services.</p>
          <h2>EXTERNAL THIRD PARTIES</h2>
          <p>Service providers who act as processors or joint controllers who provide digital marketing, advertising and/or analytics services, such as Yahoo! and Google. For more details about those partners please email us

            Professional advisers acting as processors or joint controllers including lawyers, bankers, auditors and insurers based who provide consultancy, banking, legal, insurance and accounting services.</p>
          <p>HM Revenue & Customs, regulators and other authorities acting as processors or joint controllers based in the United Kingdom or Jersey who require reporting of processing activities in certain circumstances.</p>
      </div>
    </>
  )
}

export default PrivacyPolicyContent