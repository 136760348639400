import React,{useEffect,useState} from 'react'
import moment from "moment"
import { Link } from "react-router-dom";

function LatestArticle(props) {
    const {data} = props

    const [mappedArray,setMappedArray] = useState([]);

    useEffect(()=>{
        let finalArray=[];
        let headingArray = [];
        data?.map((elem,i)=>{
            if (i >= 5 && i<30) {
                if(!headingArray.includes(elem.summary)){
                    headingArray.push(elem.summary)
                    finalArray.push(elem);
                }
            }
        })
        setMappedArray(finalArray)
    },[data])

   

  return (
        <>
            <section class="bg-white dark:bg-gray-900">
                <div class="container px-6 py-10 mx-auto">
                    <h1 class="text-3xl font-semibold text-gray-800 capitalize lg:text-2xl dark:text-white mt-8">LATEST ARTICLES</h1>
                    <div class="grid grid-cols-1 gap-8 mt-8 lg:grid-cols-2">
                        {
                            mappedArray?.map((article,i)=>(
                                <div>
                                    <img class="relative z-10 object-cover w-full rounded-md h-96" src={(article?.image?.includes("drive"))?(`https://drive.google.com/uc?export=view&id=${article?.image?.split("/")[5]}`):(article?.image)} alt=""/>
                                    <div class="relative z-20 max-w-lg p-6 mx-auto -mt-20 bg-white rounded-md shadow dark:bg-gray-900">
                                        <Link to={`/article-overview/${article?.slug}`} class="font-semibold text-gray-800 hover:underline dark:text-white md:text-xl">
                                            <div data-id={i} >{article?.heading?.substr(0,80)}....</div>
                                        </Link>
                                        <p class="mt-3 text-sm text-gray-500 dark:text-gray-300 md:text-sm">
                                            {article?.summary}
                                        </p>
                                        <p class="mt-3 text-sm text-blue-500">{moment(article?.date).format("MMM Do YY")}</p>
                                    </div>
                                </div>
                            ))

                        }
                    </div>
                </div>       
            </section>
        </>
    )
}

export default LatestArticle